import 'lazysizes';

const commonScripts = () => {
	// search toggle
	const searchTrigger = document.getElementById('showSearch');
	const searchPhrase = document.getElementById('searchPhrase');

	searchTrigger.onclick = e => {
		e.preventDefault();

		searchPhrase.focus();
		document.body.classList.add('page--search_open');
	};

	document.body.onclick = e => {
		(e.clientY > 100) ? document.body.classList.remove('page--search_open') : false;
	};

	// nav toggle on mobile
	const navToggle = document.getElementById('navToggle');

	if (navToggle) {
		navToggle.onclick = e => {
			e.preventDefault();

			document.body.classList.toggle('page--nav_open');
		}
	}
};

export default commonScripts;
